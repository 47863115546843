<template>
  <ion-page>
    <Header title="Kupon Saya" backTo="/" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="container">
        <div class="filters mb-1">
          <div class="btn-filter" @click="segmentChanged('all')" :class="{'active': filter=='all'}">Semua</div>
          <div class="btn-filter" @click="segmentChanged('active')" :class="{'active': filter=='active'}">Aktif</div>
          <div class="btn-filter" @click="segmentChanged('win')" :class="{'active': filter=='win'}">Menang</div>
          <div class="btn-filter" @click="segmentChanged('fail')" :class="{'active': filter=='fail'}">Meleset</div>
        </div>
        <template v-for="c in coupons" :key="c.id">
          <Coupon :coupon="c" @open="detail(c)" />
        </template>
      </div>
      <ion-infinite-scroll
        @ionInfinite="fectchCoupon"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent
} from '@ionic/vue';
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'
import Coupon from "@/components/coupon";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header,
    Coupon,
    IonInfiniteScroll, IonInfiniteScrollContent,
    IonRefresher, IonRefresherContent
  },
  created () {
    this.fectchCoupon()
  },
  watch: {
    '$route': 'fectchCoupon'
  },
  data () {
    return {
      page: 1,
      coupons: [],
      isDisabled: false,
      filter: 'all'
    }
  },
  methods: {
    async fectchCoupon (e) {
      let data = await this.$api.get('/api/coupon', {
          params: {page: this.page, filter: this.filter }
        }).then(res => res)

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.coupons.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    async doRefresh($event) {
      this.page = 1;
      this.coupons = []
      this.isDisabled = false
      await this.fectchCoupon($event)
      $event.target.complete();
    },
    detail(c) {
      if(c.must_claim && this.checkCoupon(c.id)) {
        this.$router.push({
          name: 'Claim',
          params: { id: c.id }
        })
      }else{
        this.$router.push({
          name: 'CouponDetail',
          params: { id: c.id }
        })
      }
    },
    async checkCoupon(id) {
      return await this.$api.get(`/api/claim/coupon/${id}`)
    },
    async segmentChanged(e) {
      this.page = 1
      this.filter = e
      this.coupons = []
      this.isDisabled = false
      await this.fectchCoupon()
    }
  }
})
</script>

<style lang="scss">
.filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  .btn-filter {
    border-radius: 50px;
    color: #000;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    height: 26px;
    padding: 3px 12px;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.active {
      color: #fff;
      background: #000;
      border-color: #000;
    }
  }
}
</style>
